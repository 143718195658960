import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Masthead from "../components/masthead";
import Intro from "../components/intro";
import BannerText from "../components/banner-text";
import Gallery from "../components/gallery";

export default function GalleryTemplate({ data }) {
    const { markdownRemark } = data;
    const { title, featured_image, intro } = markdownRemark.frontmatter;

    const slides = data.slides.edges.map(obj => {
        let rObj = {};
        rObj.image = obj.node.frontmatter.image.childImageSharp.fluid;
        rObj.alt = obj.node.frontmatter.alt;
        rObj.caption = obj.node.frontmatter.caption;
        return rObj
    });

    const contact = {
        email: data.site.siteMetadata.email,
        tel: data.site.siteMetadata.telephone,
        mob: data.site.siteMetadata.mobile
    };

    return (
        <Layout>
            <SEO title={title}/>
            <Masthead title={title} image={featured_image.childImageSharp.fluid}/>

            <Intro text={intro}/>

            <Gallery slides={slides}/>

            <BannerText title="Request a quote"
                        summary={`Call our office <a href="tel:${contact.tel.replace(/\s+/g, '')}">${contact.tel}</a>, mobile <a href="tel:${contact.mob.replace(/\s+/g, '')}">${contact.mob}</a> or email <a href="mailto:${contact.email}?subject=Enquiry">${contact.email}</a>`}
                        background={data.backgroundPhones.childImageSharp.fluid}
                        arrowPosition="none"/>
        </Layout>
    )
}

export const query = graphql`
    query($pathSlug: String!, $slidesPath: String!) {
        site {
            siteMetadata {
                email
                telephone
                mobile
            }
        }
        markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
            html
            frontmatter {
                title,
                intro,
                featured_image {
                    childImageSharp {
                        fluid(maxWidth: 1400, quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
        slides: allMarkdownRemark(filter: {fileAbsolutePath: {glob: $slidesPath}}) {
            edges {
                node {
                    frontmatter {
                        image {
                            childImageSharp {
                                fluid(maxWidth: 1400, quality: 90) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        alt
                        caption
                    }
                }
            }
        }
        backgroundPhones: file(relativePath: {eq: "background-phones.png"}) {
            childImageSharp {
                fluid(maxWidth: 1400, quality: 90) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;
