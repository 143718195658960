import React, { useState } from "react";
import Image from "gatsby-image";
import SwiperCore, { Thumbs, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

const Gallery = (props) => {

    SwiperCore.use([Thumbs, Navigation]);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <div className="mb-12 container md:mb-16">
            <div className="w-100 gallery-container">
                <Swiper className="gallery"
                        thumbs={{ swiper: thumbsSwiper }}
                        loop
                        lazy={{
                            loadPrevNext: true,
                        }}
                        slidesPerView={1}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev'
                        }}>
                    { props.slides.map((slide, index) => {
                        let caption =  slide.caption ? <figcaption className="absolute bottom-0 p-2 bg-white bg-opacity-75 w-full text-center text-primary text-xs sm:text-sm md:text-lg caption">{slide.caption}</figcaption> : false;

                        return(
                            <SwiperSlide className="flex justify-center items-center h-full swiper-slide" key={index}>
                                <figure className="relative flex justify-center items-center w-full h-full">
                                    <Image fluid={slide.image}
                                        style={{width: '100%', maxHeight: '100%'}}
                                        imgStyle={{ objectFit: 'contain', opacity: 1 }}
                                        alt={slide.alt}/>
                                    {caption}
                                </figure>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>

                <div className="relative flex mt-6">
                    <button className="self-center swiper-button swiper-button-prev" aria-label="Previous"></button>

                    <Swiper className="w-full gallery-thumbnails"
                            onSwiper={setThumbsSwiper}
                            watchSlidesVisibility
                            watchSlidesProgress
                            slidesPerView={1}
                            spaceBetween={5}
                            lazy={{
                                loadPrevNext: true,
                            }}
                            breakpoints={{
                                320: {
                                    slidesPerView: 2
                                },
                                640: {
                                    slidesPerView: 3
                                },
                                768: {
                                    slidesPerView: 4
                                },
                                1024: {
                                    slidesPerView: 6
                                },
                                1280: {
                                    slidesPerView: 7
                                }
                            }}>
                        { props.slides.map((slide, index) => {

                            return(
                                <SwiperSlide className="border-solid border-4 border-transparent swiper-slide" key={index}>
                                    <div className="thumbnail">
                                        <Image fluid={{ ...slide.image, aspectRatio: 1 / 1 }}
                                            style={{width: '100%', maxHeight: '100%'}}
                                            alt={slide.alt}/>
                                    </div>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>

                    <button className="self-center swiper-button swiper-button-next" aria-label="Next"></button>
                </div>
            </div>
        </div>
    )
};

export default Gallery;
